<template>
	<div class="bidding-information">
		<div class="top-img">
			<div class="top-name" v-if="!subscribeId">招标信息库</div>
			<div class="search-input">
				<el-input v-model.trim="conPO.search" placeholder="请输入项目名称等关键词" maxlength="100" @keyup.enter.native="handleQuery"></el-input>
				<span class="search-btn" @click="handleQuery">查询</span>
			</div>
		</div>
		<div class="title-card">
			<div class="title-box">
				<div class="title">招标信息库</div>
				<div class="result">
					<span>共找到</span>
					<span class="num">{{ total }}</span>
					<span>条结果</span>
				</div>
			</div>
			<div class="condition-box">
				<div class="condition-item">
					<div class="title">省份</div>
					<div class="item-box">
						<div class="all-btn" :class="isProAll ? 'all-btn-select' : ''" @click="proAll">全部</div>
						<div class="name-box" :class="provincesStatus ? 'name-open' : ''">
							<div class="name" :class="proSelect.indexOf(item) !== -1 ? 'name-select' : ''" v-for="item in regionList" :key="item.code" @click="selectPro(item)">
								{{ item.name }}
							</div>
						</div>
					</div>
					<div class="open" @click="foldProvinces">
						<span>{{ provincesStatus ? "收起" : "展开" }}</span>
						<span class="iconfont icon-sanjiaoxing" :class="provincesStatus ? 'iconfont-open' : ''"></span>
					</div>
				</div>
				<div class="condition-item" v-show="cityData.length != 0">
					<div class="title">地级市</div>
					<div class="item-box">
						<div class="name-box" :class="cityStatus ? 'name-open' : ''">
							<div class="name" :class="citySelect.indexOf(item) !== -1 ? 'name-select' : ''" v-for="item in cityData" :key="item.code" @click="selectCity(item)">
								{{ item.name }}
							</div>
						</div>
					</div>
					<div class="open" @click="foldCity">
						<span>{{ cityStatus ? "收起" : "展开" }}</span>
						<span class="iconfont icon-sanjiaoxing" :class="cityStatus ? 'iconfont-open' : ''"></span>
					</div>
				</div>
				<div class="condition-item">
					<div class="title" style="margin-top: 10px">时间</div>
					<div class="year-box">
						<div class="all-btn" :class="isYearAll ? 'all-btn-select' : ''" @click="yearAll">不限</div>
						<div class="name-box">
							<div class="name" :class="yearSelect.indexOf(item.code) !== -1 ? 'name-select' : ''" v-for="item in yearData" :key="item.code" @click="selectYear(item)">
								{{ item.name }}
							</div>
						</div>
						<div class="custom-box">
							<div class="custom">自定义</div>
							<div>
								<el-date-picker v-model="startYear" type="month" :picker-options="startTime" :default-value="maxDate" placeholder="开始时间" value-format="yyyy-MM" @change="changeYear"></el-date-picker>
							</div>
							<div class="to">至</div>
							<div>
								<el-date-picker v-model="endYear" type="month" :picker-options="endTime" :default-value="maxDate" placeholder="结束时间" value-format="yyyy-MM" @change="changeYear"></el-date-picker>
							</div>
						</div>
					</div>
				</div>
				<div class="condition-item" style="margin-top: -10px">
					<div class="title" style="margin-top: 10px">类型</div>
					<div class="year-box">
						<div class="name-box" v-for="item in typeList" :key="item">
							<div class="name" :class="selectedTypeData.indexOf(item) !== -1 ? 'name-select' : ''" @click="selectType(item)">
								{{ item }}
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="condition-item" style="margin-top: -10px">
          <div class="title" style="margin-top: 10px">项目状态</div>
          <div class="year-box">
            <div class="name-box">
              <div
                class="name"
                :class="this.projectState ? 'name-select' : ''"
                @click="selectState()"
              >
                拟建项目
              </div>
            </div>
          </div>
        </div> -->
				<div class="condition-item" v-show="selectedProData.length != 0 || selectedCityData.length != 0 || selectedYearData.length != 0 || selectedTypeData.length != 0 || this.projectState">
					<div class="title">已选条件</div>
					<div class="selected-box">
						<div class="name" v-for="(item, i) in selectedProData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 1)"></div>
						</div>
						<div class="name" v-for="(item, i) in selectedCityData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 2)"></div>
						</div>
						<div class="name" v-for="(item, i) in selectedYearData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 3)"></div>
						</div>
						<div class="name" v-for="(item, i) in selectedTypeData" :key="item">
							<div>{{ item }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 4)"></div>
						</div>
						<div class="name" v-show="this.projectState">
							<div>拟建项目</div>
							<div class="clear-icon" @click="delSelectData(null, null, 5)"></div>
						</div>
					</div>
					<div class="empty-btn" @click="emptySelect">清空筛选条件</div>
				</div>
			</div>
		</div>
		<div class="list-box">
			<div class="empty-box" v-show="total == 0">
				<empty :name="'暂无数据'"></empty>
			</div>
			<div class="list-item" v-for="(item, index) in pageBidding" :key="index" @click="toDetail(item)">
				<div class="name-top flex items-center justify-between">
					<el-tooltip effect="light" :content="item.projectName" placement="bottom-start">
						<div v-html="item.projectNameHighLight || item.projectName" class="text-ellipsis"></div>
					</el-tooltip>
					<collect v-model="item.isCollection" :ab="false" :itemId="item.tenderId" type='招标信息' />
				</div>
				<div class="bottom-box">
					<div class="tag-bottom">
						<div class="tag-item">
							招标单位：
							<span v-html="item.tendereeHighLight || item.tenderee || '-'"></span>
						</div>
						<div class="tag-item">地址：{{ item.city }}</div>
					</div>
					<div class="date">发布时间：{{ item.publishedDate }}</div>
				</div>
				<div class="bottom-content-box" v-show="item.contentsHighLight">
					<span class="contentTitle">内容：</span>
					<span v-html="item.contentsHighLight"></span>
				</div>
			</div>
		</div>
		<div class="pagination-box">
			<pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0" :key="paginationKey"></pagination>
		</div>
		<login-modal ref="loginModal"></login-modal>
		<member-modal ref="memberModal"></member-modal>
		<ToPayOrMember ref="toPayOrMember" :showCommonText="false" :text="text"></ToPayOrMember>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import LoginModal from "@/components/loginModal";
import Empty from "@/components/Empty";
import MemberModal from "@/components/MemberModal";
import ToPayOrMember from "@/components/ToPayOrMember";
import collect from "@/components/collect";
import {mapGetters} from "vuex";
export default {
	components: {
		Pagination,
		LoginModal,
		Empty,
		MemberModal,
		ToPayOrMember,
		collect
	},
	props:{
		subscribeId:{
			type:String,
			default:''
		}
	},
	data() {
		return {
			cityData: [], // 市级数据
			provincesStatus: false, // 省份展开收起状态
			cityStatus: false, // 地级市展开收起状态
			proSelect: [], // 选中的省份
			isProAll: true, // 省份是否全选
			citySelect: [], // 选中的地级市
			isYearAll: true, // 年份是否全选
			yearSelect: [], // 选中的年份
			projectState: false,
			yearData: [
				{
					name: "今天",
					code: "1",
				},
				{
					name: "近3天",
					code: "3",
				},
				{
					name: "近7天",
					code: "7",
				},
				{
					name: "近30天",
					code: "30",
				},
				{
					name: "近60天",
					code: "60",
				},
				{
					name: "近90天",
					code: "90",
				},
				{
					name: "近180天",
					code: "180",
				},
				{
					name: "近一年",
					code: "365",
				},
			],
			typeList: [],
			selectedProData: [], // 已选的省级数据
			selectedCityData: [], // 已选地级市数据
			selectedYearData: [], // 已选地年份数据
			selectedTypeData: [],
			startYear: "",
			endYear: "",
			pageReport: [],
			conPO: {
				platform: 1,
				search: "",
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			total: 0,
			pageBidding: [],
			paginationKey: "",
			memberId: "",
			text: "",
			minDate: "",
			maxDate: "",
		};
	},
	mounted() {
		this.getTimeSelector();
		this.getBidProjectType();
		this.getTenderInfo();
		if (this.$session.getUsers()) {
			this.memberId = this.$session.getUsers().memberId;
		}
	},
	computed: {
		startTime() {
			return {
				disabledDate: time => {
					let disableTime;
					if (this.endYear) {
						disableTime = time.getTime() > new Date(this.endYear).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						console.log("this.maxDate", this.maxDate);
						disableTime = time > this.maxDate || time < this.minDate;
					}

					return disableTime;
				},
			};
		},
		endTime() {
			return {
				disabledDate: time => {
					if (this.startYear) {
						return time.getTime() < new Date(this.startYear).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						return time > this.maxDate || time < this.minDate;
					}
				},
			};
		},
		...mapGetters(["regionList"]),
	},
	methods: {
		getTimeSelector() {
			this.$api.industry.getTimeSelectorTender().then(res => {
				this.minDate = new Date(res.data.minDateTime + " 00:00:00");
				this.maxDate = new Date(res.data.maxDateTime + ` 23:59:59`);
			});
		},
		getBidProjectType() {
			this.$api.successful
				.getBidProjectType()
				.then(res => {
					this.typeList = res.data;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 拟建项目
		selectState() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.projectState = true;
				this.handleQuery();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 点击搜索
		handleQuery() {
			if (this.$session.getUsers()) {
				this.$refs.pagination.page = 1;
				this.conPO.pageNum = 1;
				this.conPO.pageSize = 10;
				this.getTenderInfo();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 获取招标信息库列表
		getTenderInfo() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			let {conPO, proSelect, citySelect, yearSelect, startYear, endYear, selectedTypeData, projectState} = this;
			let provinces = [];
			proSelect.map(item => {
				provinces.push(item.code);
			});
			conPO.provinceCodes = provinces.toString();

			let citys = [];
			citySelect.map(item => {
				citys.push(item.code);
			});
			conPO.cityCodes = citys.toString();


			conPO.dayCount = yearSelect.toString();
			conPO.dateStart = startYear;
			conPO.dateEnd = endYear;
			conPO.projectType = selectedTypeData.toString();
			conPO.projectState = projectState ? "拟建项目" : "";
			let contentsHighLightReg = /[\u4e00-\u9fa5|\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]+|<em>[^<]*<\/em>/g;

			let func =this.$api.industry.getTenderInfo(conPO)
			if(this.subscribeId){
				conPO.subscribeId=this.subscribeId
				func=this.$api.industry.getSubscribeTenderInfoPage(conPO)
			} 
			func.then(res => {
					this.pageBidding = res.rows;
					this.total = res.total;
					res.rows.forEach(item => {
						item.publishedDate = item.publishedDate.slice(0, 10);
						item.contentsHighLight = item.contentsHighLight.match(contentsHighLightReg).join("");
					});
					this.pageBidding = res.rows;
					loading.close();
				})
				.catch(msg => {
					loading.close();
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		 
		// 请求市区城
		getCity(item) {
			let obj = {
				parentRegion: item.regionId,
				isLazyLoad: 10011001,
				state: 10021001,
				hasChildren: true
			}
			this.$api.system.regionList(obj).then(res => {
				if (res.data.length > 0) {
					res.data.map(item => {
						item.name = item.regionFullname
						item.code = item.unionCode
					})
					let data = res.data;
					this.cityData = [...data];
				}
			});
		},
		// 展开收起省份
		foldProvinces() {
			this.provincesStatus = !this.provincesStatus;
		},
		// 展开收起地级市
		foldCity() {
			this.cityStatus = !this.cityStatus;
		},
		// 单选省份
		selectPro(item) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let {proSelect, cityData, selectedProData, citySelect, selectedCityData} = this;
				let valueIndex = proSelect.indexOf(item);
				if (valueIndex == -1) {
					this.proSelect.push(item);
					this.selectedProData.push(item)
					if (this.proSelect.length == 0 && this.citySelect.length == 0) {
						this.isProAll = true;
					} else {
						this.isProAll = false;
					}
					// 判断市级数据中是否已经存在当前省的市级数据
					if (citySelect.some(key => key.code.slice(0, 2) == item.code.slice(0, 2))) {
						// 处理市级数据（不需要当前省级的市级数据）
						let newCitySelect = [];
						citySelect.forEach(key => {
							if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
								newCitySelect.push(key);
							}
						});
						this.citySelect = newCitySelect;
						// 处理已选条件的市级数据（不需要当前省级的市级数据）
						let newSelectedCityData = [];
						selectedCityData.forEach(key => {
							if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
								newSelectedCityData.push(key);
							}
						});
						this.selectedCityData = newSelectedCityData; 
					}
					this.handleQuery();
					// 选择
					this.getCity(item);
				} else {
					// 取消选择
					proSelect.splice(valueIndex, 1);
					// 删除对应的市级数据
					let cityResult = [];
					cityData.forEach(key => {
						if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
							cityResult.push(key);
						}
					});
					this.cityData = cityResult;
					// （删除已选的省级数据）比较 item和selectedProData取出不同的值
					let itemArr = [item];
					let selectedCodes = [];
					itemArr.forEach(({name}) => {
						selectedCodes.push(name);
					});
					let selectedResult = selectedProData.filter(({name}) => !selectedCodes.includes(name));
					this.selectedProData = selectedResult;
					this.handleQuery();
				}
				if (this.proSelect.length == 0 && this.citySelect.length == 0) {
					this.isProAll = true;
				} else {
					this.isProAll = false;
				}
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 全选省份
		proAll() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				if (this.proSelect.length == 0 && this.citySelect.length == 0) {
					return;
				}
				this.isProAll = !this.isProAll;
				if (this.isProAll) {
					this.selectedProData = [];
					this.proSelect = [];
					this.cityData = [];
					this.selectedCityData = [];
					this.citySelect = [];
				}
				this.handleQuery();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 单选地级市
		selectCity(item) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let {citySelect, selectedCityData, proSelect, selectedProData} = this;
				let valueIndex = citySelect.indexOf(item);
				if (valueIndex == -1) {
					// 选择
					citySelect.push(item);
					this.selectedCityData = [...selectedCityData, ...[item]];
					// 处理省级数据（不需要当前市级的省级数据）
					let newCitySelect = [];
					proSelect.forEach(key => {
						if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
							newCitySelect.push(key);
						}
					});
					this.proSelect = newCitySelect;
					// 处理已选条件的省级数据（不需要当前市级的省级数据）
					let newSelectedProData = [];
					selectedProData.forEach(key => {
						if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
							newSelectedProData.push(key);
						}
					});
					this.selectedProData = newSelectedProData;
					if (this.proSelect.length == 0) {
						this.isProAll = false;
					}
					this.handleQuery();
				} else {
					// 取消选择
					citySelect.splice(valueIndex, 1);
					// 比较 item和selectedCityData取出不同的值
					let itemArr = [item];
					let selectedCodes = [];
					itemArr.forEach(({code}) => {
						selectedCodes.push(code);
					});
					let selectedResult = selectedCityData.filter(({code}) => !selectedCodes.includes(code));

					if (selectedResult.length == 0) {
						// 无选中的市时
						this.isProAll = true;
					}
					this.selectedCityData = selectedResult;
					this.handleQuery();
				}
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 全选年份
		yearAll() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				if (this.yearSelect.length == 0 && this.startYear == "" && this.endYear == "") {
					return;
				}
				this.isYearAll = !this.isYearAll;
				if (this.isYearAll) {
					this.startYear = "";
					this.endYear = "";
					this.selectedYearData = [];
					this.yearSelect = [];
				}
				this.handleQuery();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 单选年份
		selectYear(item) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let {yearSelect} = this;
				let valueIndex = yearSelect.indexOf(item.code);
				if (valueIndex == -1) {
					// 选择
					this.yearSelect = [item.code];
					this.selectedYearData = [item];
					this.startYear = "";
					this.endYear = "";
					this.handleQuery();
				} else {
					// 取消选择
					this.yearSelect = [];
					this.selectedYearData = [];
					this.handleQuery();
				}
				if (this.yearSelect.length == 0) {
					this.isYearAll = true;
				} else {
					this.isYearAll = false;
				}
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 改变时间
		changeYear() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.isYearAll = false;
				this.selectedYearData = [];
				this.yearSelect = [];
				if ((this.startYear == "" || this.startYear == null) && (this.endYear == "" || this.endYear == null)) {
					this.isYearAll = true;
				}
				this.handleQuery();
			} else {
				this.startYear = null;
				this.endYear = null;
				this.$refs.loginModal.visible = true;
			}
		},
		// 删除单条所选内容
		delSelectData(item, index, type) {
			if (type == 1) {
				// 删除省
				let {proSelect, citySelect, cityData} = this;
				this.selectedProData.splice(index, 1);
				// 删除对应的市级数据
				let cityResult = [];
				cityData.forEach(key => {
					if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
						cityResult.push(key);
					}
				});
				this.cityData = cityResult;
				let newData = proSelect.filter(key => ![item.name].includes(key.name));
				// if (newData.length == 0 && this.selectedCityData.length == 0) {
				//   // 无选中的省，市
				//   this.isProAll = true;
				//   this.selectedProData = [];
				//   this.proSelect = [];
				//   this.cityData = [];
				//   this.selectedCityData = [];
				//   this.citySelect = [];
				// }
				this.proSelect = newData;
				if (proSelect.length == 1 && citySelect.length == 0) {
					this.cityData = [];
				}
				this.getTenderInfo();
				if (this.proSelect.length == 0 && this.citySelect.length == 0) {
					this.isProAll = true;
				} else {
					this.isProAll = false;
				}
			} else if (type == 2) {
				// 删除市
				let {citySelect, selectedProData} = this;
				this.selectedCityData.splice(index, 1);
				let newData = citySelect.filter(key => ![item.name].includes(key.name));
				if (newData.length == 0) {
					this.selectedCityData = [];
					this.citySelect = [];
				}
				if (newData.length == 0 && this.proSelect.length == 0) {
					this.isProAll = true;
				}
				this.citySelect = newData;
				if (citySelect.length == 1 && selectedProData.length == 0) {
					this.cityData = [];
				}
				this.handleQuery();
			} else if (type == 3) {
				// 删除年份
				this.isYearAll = true;
				this.selectedYearData = [];
				this.yearSelect = [];
				this.handleQuery();
			} else if (type == 4) {
				this.selectedTypeData.splice(index, 1);
				this.handleQuery();
			} else if (type == 5) {
				this.projectState = false;
				this.handleQuery();
			}
		},
		// 点击类型
		selectType(item) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let {selectedTypeData} = this;
				let valueIndex = selectedTypeData.indexOf(item);
				if (valueIndex == -1) {
					// 选择
					selectedTypeData.push(item);
					this.selectedTypeData = selectedTypeData;
					this.handleQuery();
				} else {
					// 取消选择
					selectedTypeData.splice(valueIndex, 1);
					this.selectedTypeData = selectedTypeData;
					this.handleQuery();
				}
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 清空筛选条件
		emptySelect() {
			this.cityData = [];
			this.proSelect = [];
			this.isProAll = true;
			this.citySelect = [];
			this.isYearAll = true;
			this.yearSelect = [];
			this.selectedProData = [];
			this.selectedCityData = [];
			this.selectedYearData = [];
			this.startYear = "";
			this.endYear = "";
			this.selectedTypeData = [];
			this.projectState = false;
			this.handleQuery();
		},
		// 点击列表查看详情
		toDetail(item) {
			console.log("item", item);
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				const loading = this.$loading({
					lock: true,
					text: "加载中...",
					spinner: "el-icon-loading",
					background: "rgba(255, 255, 255, 0.3)",
					fullscreen: false,
				});
				let params = {
					moduleCode: 1006,
					childModuleCode: 10061003,
					type: 2,
				};
				this.$api.industry
					.getMemberPermission(params)
					.then(res => {
						console.log("res", res);
						if (res.data != 0) {
							const routeUrl = this.$router.resolve({
								path: `/industry/bidding-detail?id=${item.tenderId}`,
							});
							window.open(routeUrl.href, "_blank");
						} else {
							this.text = "您今日免费次数已使用完，请开通权限后继续使用";
							this.$refs.toPayOrMember.openDialog();
						}
					})
					// this.$api.industry
					// 	.getTenderInfoDetail({
					// 		platform: 1,
					// 		tenderId: item.tenderId,
					// 	})
					// 	.then(res => {
					// 		let url = this.$router.resolve({
					// 			path: `/industry/bidding-detail?id=${item.tenderId}`,
					// 		});
					// 		window.open(url.href, "_blank");
					// 	})
					// .catch(msg => {
					// 	console.log("msg",msg);

					// 	if (msg.code == "10001111") {
					// 		this.$refs.toPayOrMember.openDialog();
					// 		this.text = msg?.msg;
					// 	} else {
					// 		this.$message.error(msg?.msg);
					// 	}
					// })
					.finally(() => {
						loading.close();
					});
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 分页方法
		paginChange(page, pageSize) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				// 判断会员权限
				let {memberId} = this;
				if (memberId == 7 || memberId == "") {
					this.$refs.memberModal.open = true;
					return;
				}
				this.conPO.pageNum = page;
				this.conPO.pageSize = pageSize;
				this.getTenderInfo();
			} else {
				this.paginationKey = new Date().getTime();
				this.$refs.loginModal.visible = true;
			}
		},
	},
};
</script>

<style lang="less">
@import "./index.less";
</style>
